
import Footer from "../footer";
import Header from "../header";
import WhyChooseUs from "../whyChooseus";

const WhyChooseUsPage = () => {
    return (
        <>
            <Header />
            <WhyChooseUs />
            <Footer />
        </>
    )
}
export default WhyChooseUsPage;
