
import Footer from "../footer";
import Header from "../header";
import Main from "../main";

const HomePage = () => {
    return (
        <>
            <Header />
            <Main />
            <Footer />
        </>
    )
}
export default HomePage;