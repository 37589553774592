
// import Diesel1 from "../assets/images/"

const ThirdDiv = () => {
    return (
        <third>
            <div className="last-third-div">
                <h1>We Are The Best In The Business.</h1>
                <p>We have delivered over 9 million litres of high quality diesel fuel till date,
                    and you can also be one of our esteemed customers.</p>
            </div>
        </third>
    )
}
export default ThirdDiv;