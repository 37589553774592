
import Contact from "../contact";
import Footer from "../footer";
import Header from "../header";
const ContactUs = () => {
    return (
        <>
        <Header />
        <Contact />
        <Footer />
        </>
    )
}
export default ContactUs;